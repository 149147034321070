import type { v1, ExtractFromAPI } from "@netgame/openapi";

export type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
export type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
export type Games = GamesPayload["games"];
export type GamesMenu = GamesPayload["menu"];
export type Game = NonNullable<Games>[number];

export type InitDataResponse = ExtractFromAPI<v1.paths, "/rest/app/init/", "get">;
export type InitDataResponseData = InitDataResponse["data"];
export type InitDataResponseNonNullable = NonNullable<InitDataResponse["data"]>;
export type InitPresetPackage = NonNullable<InitDataResponseNonNullable["popupPresetPackage"]>;
export type InitPresetPackageData = NonNullable<InitPresetPackage["promoOfferPreset"]>;
export type BannerPresetPackage = NonNullable<InitDataResponseData>["bannerPresetPackage"];

export type PromoResp = ExtractFromAPI<v1.paths, "/rest/page/promotions/", "get">;
export type PromotionsPayload = NonNullable<PromoResp["payload"]>;
export type Promotions = PromotionsPayload["promotions"];
export type Promo = NonNullable<Promotions>[number];
export type PromoData = NonNullable<Promo["data"]>;
export type Promotion = {
	available?: boolean;
	type?:
		| "dailyWheel"
		| "inviteQualification"
		| "emailLottery"
		| "verification"
		| "invite"
		| "tournament"
		| "offerPeriodic"
		| "offerDeposit"
		| "rankLeagueCashback"
		| "promoOfferConstructor"
		| "partnerReferralQualification"
		| "rankLeague"
		| "rankLeagueBirthdayGift"
		| "depositStreak"
		| "bingo"
		| "scratchCards"
		| "coinDrops"
		| "superCoins"
		| "season";
	data?: PromoData;
};

export type PopUpAdData = {
	popup_id: number;
	popup_name: string;
	popup_delay_time: number;
	popup_company_priority: number;
	popup_priority: number;
	popup_data: string;
	log_id: number;
};

export type PlayersActivityResponse = ExtractFromAPI<v1.paths, "/rest/players-activity/", "get">;
export type PlayersActivityArray = NonNullable<PlayersActivityResponse["data"]>;
export type PlayersActivity = PlayersActivityArray[number];
export type PlayersActivityGame = PlayersActivity["data"];

export type AllHomeResponse = ExtractFromAPI<v1.paths, "/rest/page/home/", "get">;
export type HomePayload = NonNullable<AllHomeResponse["payload"]>;
export type HomeGames = NonNullable<HomePayload["games"]>;
export type HomeGamesIssues = NonNullable<HomeGames["issues"]>;
export type HomeAllGames = NonNullable<HomeGamesIssues["all-games"]>;
export type HomeGamesList = NonNullable<HomeAllGames["games"]>;
export type HomeBanners = NonNullable<HomePayload["banners"]>;
export type HomeJackpot = NonNullable<HomePayload["jackpots"]>;
export type FirstScreenBanners = NonNullable<HomePayload["banners"]> & BannerPresetPackage;

export type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
export type TournamentData = NonNullable<TournamentResponse["data"]>;
export type TournamentPlayerData = NonNullable<TournamentResponse["playerData"]>;
export type TournamentWinners = NonNullable<TournamentData["winners"]>;
export type TournamentWinner = NonNullable<TournamentWinners[number]>;
export type Tournament = NonNullable<TournamentData>;

export type Badge = {
	percent: string;
	text: string;
	labelText?: string;
	labelTextColor?: string;
	labelBorderColor?: string;
	labelBackground?: string;
	offerBackground?: string;
	usualTextColor?: string;
	promoUsualTextColor?: string;
};
export type HowWorks = {
	cardHowItWorks?: string;
};

export type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
export type NextTournaments = NonNullable<NextTournamentsResponse["tournaments"]>;
export type NextTournament = NonNullable<NextTournaments[number]>;
export type NextTournamentWinners = NonNullable<NextTournament["winners"]>;
export type NextTournamentsCount = NonNullable<NextTournamentsResponse["totalTournamentsCount"]>;

export type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments-history/", "get">;
export type PrevTournaments = NonNullable<NextTournamentsResponse["tournaments"]>;
export type PrevTournament = NonNullable<PrevTournaments[number]>;
export type PrevTournamentWinners = NonNullable<PrevTournament["winners"]>;
export type PrevTournamentsCount = NonNullable<NextTournamentsResponse["totalTournamentsCount"]>;
export type CollectionListRecponse = ExtractFromAPI<v1.paths, "/rest/game-browser/issue/{slug}", "get">;
export type CollectionsGames = NonNullable<CollectionListRecponse["games"]>;

export type InviteFriendsResponse = ExtractFromAPI<v1.paths, "/rest/page/referrals/", "get">;
export type InviteFriendsPayload = NonNullable<InviteFriendsResponse["payload"]>;
export type ReferralInfoResponse = ExtractFromAPI<v1.paths, "/rest/page/referral-info/", "get">;
export type ReferralInfo = NonNullable<InviteFriendsPayload["referralInfo"]>;

export type PageCollectionsResponse = ExtractFromAPI<v1.paths, "/rest/page/collections/", "get">;
export type PageCollectionsPayload = NonNullable<PageCollectionsResponse["payload"]>;
export type PageCollections = NonNullable<PageCollectionsPayload["collections"]>;
export type PageCollection = NonNullable<PageCollections>[number];

export type ProfilePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/profile/", "get">>;
export type ProfileFileResponse = ExtractFromAPI<v1.paths, "/rest/player/documents/", "get">;
export type ProfileFile = {
	status?: string;
	name?: string;
	size?: number;
	sizeError?: boolean;
	data?: File;
} & ProfileFileResponse[number];

export type HowWorkItem = {
	imgSrc: string;
	title: string;
	text: string;
};

export type HowItWorks = {
	title: string;
	posts: Array<HowWorkItem>;
};

export type Start = {
	title: string;
	subTitleFirst: string;
	subTitleSecond: string;
};

export type Rewards = {
	title: string;
	posts: string[];
};

export type HowWorkData = {
	howItWork: HowItWorks;
	start: Start;
	rewards: Array<Rewards>;
	contentTitle: string;
	contentDescription: string;
	textEmptyReward: string;
};

export type Social = {
	id: string;
	name: string;
	href: string;
	icon: string;
};

export type UserData = {
	login: string;
	password: string;
};

export type RegisterData = {
	contact: string;
	password: string;
	agree: boolean;
	timezone: string;
};

export type PromoOfferPreset = {
	id: number;
	coins: number;
	entries: number;
	bestDeal: boolean;
	mostPopular: false;
	imageCash: string;
	imageBadge: string;
	usualPrice: number;
	forCompleteProfile: boolean;
	badgeLabel: string;
	savingsPercent: string;
};
export type OfferPreset = {
	preset: {
		id: number;
		coins: number;
		entries: number;
	};
	promoOfferPreset: PromoOfferPreset;
	money: number;
	status: string;
};
export type OfferConstructor = {
	groupPackages: string[];
	packages: OfferPreset[];
	ssn?: boolean;
	profile?: boolean;
	balance?: {
		entries?: number;
		winnings?: string;
		tourPoints?: number;
	};
};

export type SocketResponseTournament = {
	type: string;
	action: string;
	bets_count: number;
	winners: TournamentWinner[];
	historyId: string;
	next: Array<NextTournament>;
	prev: Array<PrevTournament>;
	playerData: TournamentPlayerData;
	data: {
		totalPoints: number;
		place: number;
	};
};
export type GameModes = "SweepStakes" | "TournamentPoints";
export type GamesFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
export type GamesFiltersData = NonNullable<GamesFiltersResponse["data"]>;
export type GamesFilters = NonNullable<GamesFiltersData["games"]>;

export enum EInsufficientFunds {
	TOURNAMENT_WITH_OFFER,
	SWEEPSTAKES_WITH_OFFER,
	NO_OFFER,
	SWEEPSTAKES_WITH_WINNINGS
}

export interface ConversionItem {
	key: string;
	label?: string;
	value: number;
}
export type WheelOfWinsInfoData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/info/", "get">;
export type WheelOfWinsWheelData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/prizes/", "get">;
export type WheelOfWinsWheelPercentData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/wheels-prizes/", "get">;
export type WheelOfWinsApplyPrizeData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/apply-prize/", "get">;
export type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

export type PrevTournamentWinner = NonNullable<PrevTournamentWinners[number]>;
export type NextTournamentWinner = NonNullable<NextTournamentWinners[number]>;
export type ExtendWinners = TournamentWinners & PrevTournamentWinners & NextTournamentWinners;
export type ExtendWinner = TournamentWinner & PrevTournamentWinner & NextTournamentWinner;
export type ExtendTournamentProps = {
	tournament: NextTournament | PrevTournament;
};
export type DetailInvite = {
	type: string;
	action: string;
	coins: number;
	entries: number;
};

export type CoinDropsData = ExtractFromAPI<v1.paths, "/rest/prize-drops/info/", "get">;
export type CoinDropsSocket = {
	playerId: string;
	playerName: string;
	prizeValue: string;
	prizeLevel: string;
	board: {
		prizes: [
			{
				prizeValue: number;
				prizeLeft: number;
			}
		];
		lastWinners: [
			{
				playerName: string;
				prizeValue: number;
			}
		];
	};
};

export type ScratchCardsInfoData = ExtractFromAPI<v1.paths, "/rest/scratch-card-lottery/get/", "get">;
export type ScratchCardsTicketPlayData = ExtractFromAPI<v1.paths, "/rest/scratch-card/prize/resolve/", "post">;
export type ScratchCardsTypes = "bronze" | "silver" | "gold";

export type BonusesResponse = ExtractFromAPI<v1.paths, "/rest/bonus-offer/get-bonuses/", "get">;

export type RouteError = {
	statusCode?: number;
	statusMessage?: string;
	message?: string;
	url?: string;
	stack?: string;
};

export type Error = RouteError | null | undefined;

export type Column = {
	headerName: string;
	field: string;
	class?: string;
	width: number;
};

export type Pagination = {
	current: number | null;
	pages: number | null;
};

export type Row = {
	[key: string]: string | number;
};

export type PlayerResponsePost = {
	type: string;
	success: boolean;
	errors?: { type: string; message: string };
	info?: {
		profileCompleted: boolean;
		name: string;
		surname: string;
		city: string;
		address: string;
		address2: string;
		zip: string;
		state: string;
		email: string;
		birthDay: string;
		birthMonth: string;
		birthYear: string;
		phone: string;
		easyProfileCompleted: boolean;
		easyProfileModuleEnabled: boolean;
		uncompletedProfileFields: string[];
	};
};

export type StatusLeaguePageResponse = ExtractFromAPI<v1.paths, "/rest/page/rank-league-info/", "get">;
export type StatusLeaguePagePayload = NonNullable<StatusLeaguePageResponse["payload"]>;
export type StatusLevels = NonNullable<StatusLeaguePagePayload["levels"]>;
export type StatusLevel = NonNullable<StatusLevels>[number];
export type StatusSections = NonNullable<StatusLeaguePagePayload["sections"]>;
export type StatusSection = NonNullable<StatusSections>[number];
export type StatusSectionLevels = NonNullable<StatusSection["levels"]>;
export type StatusSectionLevel = NonNullable<StatusSectionLevels>[number];
export type StatusSectionBenefits = NonNullable<StatusSection["benefits"]>;
export type StatusCurrentProgress = NonNullable<StatusLeaguePagePayload["currentProgress"]>;
export type StatusCurrentLevel = NonNullable<StatusCurrentProgress["currentLevel"]>;

export type RewardSection = {
	id: number;
	title: string;
	weeklyReward: number;
};

export type RewardNotice = {
	type: "contacts" | "profile";
	action: "profileComplete" | "confirmPhone" | "confirmEmail";
	reward: { coins: number };
};
